<template>
  <p class="title-info-label">{{ title }}：{{ info }}</p>
</template>

<script>
export default {
  props: {
    title: String,
    info: String,
  },
};
</script>

<style lang="scss" scoped>
.title-info-label {
  color: #666666;
  font-size: 14px;
  line-height: 20px;
}
</style>