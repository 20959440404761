<template>
  <img class="barcode" id="barcode" />
</template>

<script>
import jsbarcode from "jsbarcode";
export default {
  props: {
    patientNum: String,
  },

  mounted() {
    // 生成条形码
    jsbarcode("#barcode", this.patientNum, {
      background: "#f7f7f7",
      height: 50,
      marginRight: 0,
      displayValue: true, // 是否显示条形码下面的文字
      text: `病例号：${this.patientNum}`,
      fontSize: 14,
      textPosition: "top", // 条形码下面文字的位置
      textMargin: 6,
    });
  },
};
</script>

<style lang="scss" scoped>
.barcode {
  background-color: #f7f7f7;
}
</style>