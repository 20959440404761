<template>
  <div class="report-info-block" :style="{ width: `${widthPercent}%` }">
    <p class="report-info-block-title">{{ title }}</p>
    <div class="report-info-block-content">
      <title-info-label
        class="report-info-block-content-item"
        v-for="(item, index) of dataArray"
        :key="index"
        :title="item.title"
        :info="item.info"
        :style="{ width: item.width ? `${item.width}%` : '50%' }"
      ></title-info-label>
    </div>
  </div>
</template>

<script>
import TitleInfoLabel from "@c/report/title-info-label.vue";
export default {
  components: {
    TitleInfoLabel,
  },

  props: {
    title: String,
    dataArray: Array,
    widthPercent: {
      type: Number,
      default: 48,
    },
  },
};
</script>

<style lang="scss" scoped>
.report-info-block {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  padding: 20px 0px 7px 20px;
  margin-top: 20px;

  &-title {
    color: black;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    &-item {
      margin-bottom: 20px;
    }
  }
}
</style>